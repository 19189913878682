<template>
  <a-modal
    v-model:visible="visible"
    title="编辑部门"
    @ok="handleSubmit"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
      <a-form-item has-feedback label="部门名称" name="name">
        <a-input
          v-model:value="formState.name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="上级部门" name="parent_id">
        <a-tree-select
          v-model:value="formState.parent_id"
          :tree-data="deptListTree"
          tree-default-expand-all
          placeholder="请选择上级部门"
        />
      </a-form-item>
      <a-form-item has-feedback label="排序" name="sort" extra="数字越小越靠前">
        <a-input
          class="mini-input"
          type="number"
          v-model:value="formState.sort"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/addons/manage/api";
export default defineComponent({
  name: "update-dept",
  props: ["deptList"],
  setup(props, context) {
    // 显示弹框
    const visible = ref(false);

    // 打开弹框
    const showFromModal = (item) => {
      getDeptList();
      formState.dept_id = item.dept_id;
      formState.name = item.name;
      formState.parent_id = item.parent_id;
      formState.sort = item.sort.toString();
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      dept_id: 0,
      name: "",
      parent_id: 0,
      sort: "100",
    });

    // 格式化角色数据
    function onFormatDeptList(list) {
      const data = [];
      list.forEach((item) => {
        // 新的元素
        const netItem = {
          title: item.name,
          key: item.dept_id,
          value: item.dept_id,
        };
        // 递归整理子集
        if (item.children && item.children.length) {
          netItem["children"] = onFormatDeptList(item["children"]);
        }
        data.push(netItem);
      });
      return data;
    }

    const rules = {
      name: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      sort: [
        {
          required: true,
          message: "默认100",
          trigger: "change",
        },
      ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const deptListTree = ref();

    const getDeptList = () => {
      let deptList = onFormatDeptList(props.deptList);
      // 顶级角色
      deptList.unshift({
        title: "顶级部门",
        key: 0,
        value: 0,
      });
      deptListTree.value = deptList;
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // console.log("values", formState, toRaw(formState));
          Api.dept_update(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              formRef.value.resetFields();
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmit,
      onFormatDeptList,
      deptListTree,
    };
  },
});
</script>